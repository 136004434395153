var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"xU2CdB-7vh-hDJtBoPNOH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
    const SENTRY_DSN =
        process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    Sentry.init({
        dsn:
            SENTRY_DSN ||
            "https://789962c8ba4e47a093e036835d6e4c88@o225902.ingest.sentry.io/5239685",
        // This value is for performance monitoring and should be set to 0.0
        // unless there is an active paid subscripton for Sentry.
        tracesSampleRate: 0.0,
    });
}
